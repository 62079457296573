html,
body {
  background-color: #fff;
  font-family: Roboto, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
  color: #222;
}

#solidsteel {
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

#solidsteel::after {
  content: "";
  position: absolute;
  top: 15%;
  left: 10%;
  background-image: url("../img/razorcx-small.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 100;
  height: 80%;
  width: 80%;
  opacity: .1;
}

#solidsteel canvas {
  opacity: 1;
}

#solidsteel h1 {
  font-size: 4rem;
}

.brand {
  display: flex;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 20px;
  background-color: #fff;
}

.brand-logo {
  height: 60px;
  margin-right: 0.3em;
}

.close-x {
  margin-right: 8px;
  color: #aaa;
  cursor: pointer;
}

#header {
  margin: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  float: left;
  display: flex;
  align-items: center;
  z-index: 5;
  opacity: 0;
}

#search {
  line-height: 2rem;
  border-radius: 4px;
  padding-left: 0.8rem;
  width: 75%;
  opacity: 0.8;
  border: 0;
}

#results {
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  left: 1em;
  font-family: sans;
  font-size: 0.9rem;
  border-radius: 5px;
  top: 7em;
}

#search::placeholder {
  color: #aaa;
}

#sidebar {
  position: fixed;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 130px;
  color: #aaa;
  height: 100%;
  top: 100px;
  text-align: center;
  overflow: scroll;
  border: 1px solid #333;
}

#sidebar label {
  display: block;
  padding: 10px;
  font-size: 1.3em;
  font-weight: bold;
  background: #333;
}

#sidebar ul {
  padding: 0;
  margin: 0;
}

#sidebar li {
  font-family: monospace;
  font-size: 1.2em;
  padding: 4px;
  list-style-type: none;
  cursor: pointer;
}

.logo {
  height: 60px;
  margin-right: 0.3em;
  display: inline-block;
}

#controls {
  font-size: 11px;
  color: #eee;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  float: left;
  width: 245px;
  margin-top: 15px;
  margin-left: 15px;
  overflow-y: visible;
}

.control-label {
  padding-right: 10px;
}
